@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































.message-container {
  padding: 0 !important;
  overflow-x: scroll !important;
  display: flex;
  background-color: #fff;

  .left {
    height: 100%;

    li {
      padding: 20px;
      height: 60px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f5f7fa;
      }

      .count {
        margin-left: 4px;
      }

      .line {
        width: 2px;
        display: inline-block;
        height: 60px;
        background-color: #1890ff;
        position: absolute;
        top: 0;
        right: -1px;
      }

      &.active {
        background-color: rgba(68, 170, 238, 0.3);
      }
    }
  }

  .right {
    border-left: 1px solid #eee;
    overflow: hidden;
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .top {
      p {
        display: inline-block;
        padding: 10px;
        cursor: pointer;

        &.active {
          color: #1890ff;
          font-weight: bold;
        }
      }

      .line {
        height: 1px;
        background-color: #eee;
      }

      .search {
        padding: 10px;
        padding-left: 0px;
        padding-bottom: 15px;
      }
    }

    .middle {
      flex: 1;
      overflow: auto;
      position: relative;

      .message-item {
        word-break: break-all;
        padding: 15px 5px;
        border-bottom: 1px solid #eee;

        &:hover {
          background-color: #f5f7fa;
        }

        .mark-dot {
          display: inline-block;
          height: 10px;
          width: 10px;
          border-radius: 10px;
          margin-right: 10px;
        }

        .title {
          position: relative;
          left: -10px;
          font-weight: bold;
          color: black;
          line-height: 1.6;
        }

        .content {
          color: black;
        }

        .time {
          margin-left: 15px;
          color: #5e5e5e;
          font-size: 13px;
        }

        .msg-link {
          color: #1890ff;
          cursor: pointer;
        }
      }

      .empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .bottom {
      border-top: 1px solid #eee;
    }
  }

  .mr-default {
    margin-right: 10px;
  }
}

.slide-fade-enter,
.slide-fade-leave-to {
  animation: slide 0.2s;
}

@keyframes slide {
  0% {
    transform: translateX(30%);
  }

  50% {
    transform: translateX(70%);
  }

  100% {
    transform: translateX(100%);
  }
}
